function hideShowInvoiceDetails() {
  const requiresInvoiceInput = document.querySelector('#order_requires_invoice');
  if (requiresInvoiceInput) {
    requiresInvoiceInput.addEventListener('change', function(e){
      const invoiceDetails = document.querySelector('.invoice-details');
      if (invoiceDetails) {
        invoiceDetails.classList.toggle('d-none');
      }
    });
  }
}

function addQuantityToInput() {
  const inputAddIcon = document.querySelector('.cart-product-quantity .fa-plus-circle');
  if (inputAddIcon) {
    inputAddIcon.addEventListener('click', function(e){
      const input = document.querySelector('#order_product_quantity');
      if (input) {
        input.value = Number(input.value) + 1;
      }
    });
  }
}

function removeQuantityToInput() {
  const inputAddIcon = document.querySelector('.cart-product-quantity .fa-minus-circle');
  if (inputAddIcon) {
    inputAddIcon.addEventListener('click', function(e){
      const input = document.querySelector('#order_product_quantity');
      if (input && Number(input.value) > 1) {
        input.value = Number(input.value) - 1;
      }
    });
  }
}
export { hideShowInvoiceDetails, addQuantityToInput, removeQuantityToInput };
