import "bootstrap";
import { controlSidebar } from '../components/sidebar';
import { hideShowInvoiceDetails, addQuantityToInput, removeQuantityToInput } from '../components/cart';

controlSidebar();

const landingPage = document.querySelector("[data-page-id='pages-home']");
const teamsIndexPage = document.querySelector("[data-page-id='teams-index']");
const newsPage = document.querySelector("[data-page-id='pages-noticias']");
const cartPage = document.querySelector("[data-page-id='orders-cart']");
const confirmCartPage = document.querySelector("[data-page-id='orders-confirm_cart']");
const editOrderPage = document.querySelector("[data-page-id='orders-edit']");
const productPage = document.querySelector("[data-page-id='products-show']");

if (landingPage) {
  // Register service worker.
  if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      navigator.serviceWorker.register('/service-worker.js')
          .then((reg) => {
            console.log('Service worker registered.', reg);
          });
    });
  }

  ScrollReveal().reveal('.scroll-reveal',
                        {
                            distance: '20%',
                            origin: 'top',
                            opacity: 0,
                            easing: 'ease-in',
                            delay: 500
                        });
}

if (newsPage) {
  ScrollReveal().reveal('.card-post');
}
if (teamsIndexPage) {
  ScrollReveal().reveal('.card-team');
}
if (cartPage || confirmCartPage || editOrderPage) {
  hideShowInvoiceDetails();
}
if (productPage) {
  addQuantityToInput();
  removeQuantityToInput();
}
